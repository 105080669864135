var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"label":"Pièce","outlined":"","hide-details":"","dense":"","value":_vm.value,"filter":_vm.customFilter,"search-input":_vm.search,"items":_vm.partList,"loading":_vm.loading,"item-value":"_id"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
var select = ref.select;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"pl-1 white--text",attrs:{"input-value":selected,"close":"","color":"primary"},on:{"click":select,"click:close":function($event){return _vm.$emit('remove', item)}}},'v-chip',attr,false),on),[_c('div',{staticClass:"rounded-pill white primary--text mr-1 px-2",staticStyle:{"padding":"2px"}},[_vm._v(" "+_vm._s(item.batch.number)+" ")]),_c('span',{domProps:{"textContent":_vm._s(((item.serialNumber) + " (" + (item.partNumber.name) + ")"))}})])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',{staticStyle:{"max-width":"60px"}},[_c('div',{staticClass:"rounded-xl primary white--text py-1 px-2 text-center",staticStyle:{"min-width":"52px","max-width":"52px"}},[_vm._v(" "+_vm._s(item.batch.number)+" ")])]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.serialNumber)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(((item.partNumber.name) + " (" + (item.partType.name) + ")"))}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }