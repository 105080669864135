<template>
  <ag-card-container
    title="Traçabilité descendante"
    fluid
    no-content-padding
  >
    <v-container fluid class="py-0">
      <filter-selection :loading="loadingData" @validate="fetchData" />
    </v-container>
    <ag-card-container
      v-if="resultPartList.length"
      :search.sync="search"
      title="Résultats"
      fluid
      no-content-padding
    >
      <template #left>
        <v-btn
          :disabled="!resultPartList.length || loadingData"
          color="success"
          fab
          x-small
          :loading="loadingExport"
          @click="exportExcel"
        >
          <v-icon>file_download</v-icon>
        </v-btn>
      </template>
      <part-list
        :value="resultPartList"
        :loading="loadingData"
        :search="search"
      />
    </ag-card-container>
  </ag-card-container>
</template>

<script>
import AgCardContainer from 'src/components/common/AgCardContainer'
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'
import { createExcelFile } from 'src/services/drawExcelService'
import FilterSelection from '../components/FilterSelection'
import PartList from '../components/PartList'

export default {
  name: 'TraceabilityDescContainer',
  components: {
    AgCardContainer,
    FilterSelection,
    PartList,
  },
  data: () => ({
    resultPartList: [],
    loadingData: false,
    loadingExport: false,
    search: '',
  }),
  methods: {
    ...mapActions('ui', {
      showErrorNotification: 'showErrorNotification',
    }),
    async fetchData ({ filterType, partSelection, batchSelection }) {
      try {
        this.loadingData = true
        if (filterType === 'Pièce') {
          this.resultPartList = await Repositories.part.getList(
            {
              traceability: partSelection,
            },
          )
        }
        else {
          const tempPartSelection = await Repositories.part.getList({ batch: batchSelection })
          if (tempPartSelection.length && tempPartSelection[0].partType.name === 'Module') {
            this.resultPartList = tempPartSelection
          }
          else {
            this.resultPartList = await Repositories.part.getList(
              {
                traceability: { $in: tempPartSelection.map(part => part._id) },
              },
            )
          }
        }
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingData = false
      }
    },
    async exportExcel () {
      try {
        this.loadingExport = true
        const exportConfig = {
          columns: [
            { header: 'Numéro de série', key: 'serialNumber' },
            { header: 'Référence', key: 'partNumber' },
            { header: 'Type de référence', key: 'partType' },
            { header: 'Numéro d\'OF', key: 'batch' },
            { header: 'Derogéé', key: 'exception' },
            { header: 'Retouchée', key: 'retouched' },
            { header: 'Rebutée', key: 'rejected' },
            { header: 'Detruite', key: 'destroyed' },
            { header: 'Id', key: '_id' },
          ],
          view: [{ state: 'frozen', xSplit: 1, ySplit: 1 }],
          sheetName: 'Liste des pièces',
        }
        const data = this.resultPartList.map(item => ({
          _id: item._id,
          serialNumber: item.serialNumber,
          partNumber: item.partNumber.name,
          partType: item.partType.name,
          batch: item.batch.number,
          exception: item.exception,
          retouched: item.retouched,
          rejected: item.rejected,
          destroyed: item.destroyed,
        }))
        await createExcelFile([{ dataToExport: data, config: exportConfig }], 'Liste des pièces')
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingExport = false
      }
    },
  },
}
</script>
