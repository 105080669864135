<template>
  <v-row class="my-0" justify="center" align="center">
    <v-col cols="auto">
      <v-select
        v-model="filterType"
        outlined
        dense
        label="Type de recherche"
        :items="filterTypeList"
        hide-details
      />
    </v-col>
    <v-col v-if="filterType === 'OF'" cols="auto">
      <of-selection v-model="batchSelection" />
    </v-col>
    <v-col v-if="filterType === 'Pièce'" cols="auto">
      <part-selection v-model="partSelection" @remove="removePart" />
    </v-col>
    <v-col cols="auto">
      <v-btn
        color="accent"
        :loading="loading"
        :disabled="!canValidate"
        @click="$emit('validate', {filterType, partSelection, batchSelection})"
      >
        Valider
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import OfSelection from './OfSelection'
import PartSelection from './PartSelection'

export default {
  name: 'FilterSelection',
  components: { OfSelection, PartSelection },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    filterType: '',
    filterTypeList: [
      'Pièce',
      'OF',
    ],
    batchSelection: null,
    partSelection: null,
  }),
  computed: {
    canValidate () {
      if (this.filterType === 'Pièce' && this.partSelection) return true
      if (this.filterType === 'OF' && this.batchSelection) return true
      return false
    },
  },
  watch: {
    filterType: {
      immediate: false,
      handler () {
        this.batchSelection = null
        this.partSelection = null
      },
    },
  },
  methods: {
    removePart () {
      this.partSelection = null
    },
  },
}
</script>
